html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
progress {
  vertical-align: baseline;
}
template,
[hidden] {
  display: none;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline-width: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}
optgroup {
  font-weight: bold;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
@font-face {
  font-family: Larsseit;
  font-weight: 400;
  font-style: normal;
  src: url("43f162e26bd3213eb9ef04d47e52e3b9.woff2") format('woff2');
}
@font-face {
  font-family: Larsseit;
  font-weight: 400;
  font-style: italic;
  src: url("d62fdaaec4cbcbe564e9db0c879e0746.woff2") format('woff2');
}
@font-face {
  font-family: Larsseit;
  font-weight: 500;
  font-style: normal;
  src: url("6b1ff447e3a5e5b603b6280082565814.woff2") format('woff2');
}
@font-face {
  font-family: Larsseit;
  font-weight: 500;
  font-style: italic;
  src: url("665553991002f634150c17c912d24fc0.woff2") format('woff2');
}
@font-face {
  font-family: Larsseit;
  font-weight: 700;
  font-style: normal;
  src: url("7e51e477c3276e68c8690251ecbb95f6.woff2") format('woff2');
}
@font-face {
  font-family: Larsseit;
  font-weight: 700;
  font-style: italic;
  src: url("963dce73df8b5f91353413ff39b1342e.woff2") format('woff2');
}
html {
  height: 100%;
}
body {
  background: #fff;
  overflow-x: hidden;
  min-height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Larsseit, San Francisco, Roboto, Segoe UI, Helvetica Neue, Lucida Grande, sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.page {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.background {
  background-color: #fff;
  width: 100%;
  position: relative;
  z-index: 1;
}
div.showcase {
  opacity: 1;
  transition: opacity 0.35s ease;
}
div.showcase.pre-appear-element,
div.showcase .pre-appear-element {
  opacity: 0;
}
.footer.pre-leave {
  opacity: 0;
}
.pre-appear {
  opacity: 0;
  transform: translateY(20px);
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transition: opacity 0.4s ease, transform 0.4s ease;
}
.content.leave {
  opacity: 0;
  transform: translateY(20px);
}
.menu-control-light:not(:checked) + label {
  filter: brightness(0) invert(1);
}
.menu-control-light:not(:checked) ~ .showcase-light .logo,
.menu-control-light:not(:checked) ~ .showcase-light .logotype,
.menu-control-light:not(:checked) ~ .showcase-light .menu {
  filter: brightness(0) invert(1);
  text-shadow: 0px 2px 24px rgba(0,0,0,0.16);
}
.header {
  display: flex;
  align-items: center;
  width: calc((((100vw - 60px) / 2) * 2) + 20px);
  margin-top: 40px;
  margin-bottom: 64px;
}
@media (min-width: 767px) {
  .header {
    width: calc((((100vw - 280px) / 6) * 6) + 200px);
  }
}
@media (min-width: 1023px) {
  .header {
    width: calc((((100vw - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 1520px) {
  .header {
    width: calc((((1520px - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 1600px) {
  .header {
    width: calc((((1520px - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 767px) {
  .header {
    margin-top: 40px;
  }
}
@media (min-width: 1023px) {
  .header {
    margin-top: 40px;
  }
}
@media (min-width: 1520px) {
  .header {
    margin-top: 40px;
  }
}
@media (min-width: 1600px) {
  .header {
    margin-top: 40px;
  }
}
@media (min-width: 767px) {
  .header {
    margin-bottom: 104px;
  }
}
@media (min-width: 1023px) {
  .header {
    margin-bottom: 136px;
  }
}
@media (min-width: 1520px) {
  .header {
    margin-bottom: 160px;
  }
}
@media (min-width: 1600px) {
  .header {
    margin-bottom: 240px;
  }
}
.header.showcase,
.header.showcase-light,
.header.showcase-darklight,
.header.showcase-dark {
  position: absolute;
}
@media (min-width: 1023px) {
  .header {
    min-height: 51.2px;
  }
}
@media (min-width: 1520px) {
  .header {
    width: calc(100vw - 200px);
    max-width: 2000px;
  }
}
.header .logo {
  display: inline-block;
  text-decoration: none;
  position: absolute;
  font-size: 16px;
  line-height: 1.6;
  padding: 0;
  margin: 0;
  background: url("9332b6bff2d8a192adbe98f707294648.png") center no-repeat;
  background-size: contain;
  width: 27px;
  height: 50px;
  transform: translateY(20px);
}
@media (min-width: 767px) {
  .header .logo {
    font-size: 16px;
    line-height: 1.6;
  }
}
@media (min-width: 1023px) {
  .header .logo {
    font-size: 20px;
    line-height: 1.6;
  }
}
@media (min-width: 1520px) {
  .header .logo {
    font-size: 24px;
    line-height: 1.6;
  }
}
@media (min-width: 1023px) {
  .header .logo {
    width: 33px;
    height: 56px;
    transform: translateY(3px);
  }
}
.header .logo::before {
  position: absolute;
  opacity: 0.005;
  left: 0;
  top: 0;
  width: 5px;
  height: 5px;
  background-size: contain;
  animation: alternateLogos 1s forwards 1 1s;
  pointer-events: none;
  content: '';
  z-index: -1;
}
.header .logo.alternating {
  animation: alternateLogos 4s forwards infinite 1s;
}
.header .logo:not(a) {
  width: calc((((100vw - 60px) / 2) * 1) + 0px);
}
@media (min-width: 767px) {
  .header .logo:not(a) {
    width: calc((((100vw - 280px) / 6) * 1) + 0px);
  }
}
@media (min-width: 1023px) {
  .header .logo:not(a) {
    width: calc((((100vw - 520px) / 12) * 2) + 40px);
  }
}
@media (min-width: 1520px) {
  .header .logo:not(a) {
    width: calc((((1520px - 520px) / 12) * 2) + 40px);
  }
}
@media (min-width: 1600px) {
  .header .logo:not(a) {
    width: calc((((1520px - 520px) / 12) * 2) + 40px);
  }
}
.header .logotype {
  margin: 0;
  font-weight: normal;
  display: none;
  color: #2b2e32;
  font-size: 16px;
  width: calc((((100vw - 60px) / 2) * 1) + 0px);
  line-height: 1.3;
  margin-left: calc((((100vw - 60px) / 2) * 1) + 0px + 20px);
  margin-top: 8px;
}
@media (min-width: 767px) {
  .header .logotype {
    width: calc((((100vw - 280px) / 6) * 4) + 120px);
  }
}
@media (min-width: 1023px) {
  .header .logotype {
    width: calc((((100vw - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1520px) {
  .header .logotype {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1600px) {
  .header .logotype {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 767px) {
  .header .logotype {
    margin-left: calc((((100vw - 280px) / 6) * 2) + 40px + 40px);
  }
}
@media (min-width: 1023px) {
  .header .logotype {
    margin-left: calc((((100vw - 520px) / 12) * 2) + 40px + 40px);
  }
}
@media (min-width: 1520px) {
  .header .logotype {
    margin-left: calc((((1520px - 520px) / 12) * 2) + 40px + 40px);
  }
}
@media (min-width: 1600px) {
  .header .logotype {
    margin-left: calc((((1520px - 520px) / 12) * 2) + 40px + 40px);
  }
}
@media (min-width: 767px) {
  .header .logotype {
    margin-top: 8px;
  }
}
@media (min-width: 1023px) {
  .header .logotype {
    margin-top: 8px;
  }
}
@media (min-width: 1520px) {
  .header .logotype {
    margin-top: 8px;
  }
}
@media (min-width: 1600px) {
  .header .logotype {
    margin-top: 8px;
  }
}
@media (min-width: 1023px) {
  .header .logotype {
    display: block;
  }
}
@media (min-width: 1520px) {
  .header .logotype {
    margin-left: calc((((100vw - 60px) / 2) * 0) + -20px + 20px);
  }
}
.header .logotype a {
  color: #2b2e32;
  text-decoration: none;
}
@media (min-width: 1520px) and (min-width: 767px) {
  .header .logotype {
    margin-left: calc((((100vw - 280px) / 6) * 0) + -40px + 40px);
  }
}
@media (min-width: 1520px) and (min-width: 1023px) {
  .header .logotype {
    margin-left: calc((((100vw - 520px) / 12) * 1) + 0px + 40px);
  }
}
@media (min-width: 1520px) and (min-width: 1520px) {
  .header .logotype {
    margin-left: calc((((1520px - 520px) / 12) * 1) + 0px + 40px);
  }
}
@media (min-width: 1520px) and (min-width: 1600px) {
  .header .logotype {
    margin-left: calc((((1520px - 520px) / 12) * 1) + 0px + 40px);
  }
}
@-moz-keyframes alternateLogos {
  14% {
    background-image: url("2dc61e7239e55b5f07f5e4caa4c218c4.png");
  }
  28% {
    background-image: url("fa8b9450a9cbff103ba90ff2f27b549e.png");
  }
  42% {
    background-image: url("6b264daedda083d237171b5d760600d0.png");
  }
  56% {
    background-image: url("aacafae4b38e9e5a8ae36f8cbe359684.png");
  }
  70% {
    background-image: url("beba198cee08798f03a09a42e92e922e.png");
  }
  84% {
    background-image: url("d8382011f33351d866213cdca342501d.png");
  }
  98% {
    background-image: url("e18aeb55df190f3f8f00af6150a216bb.png");
  }
}
@-webkit-keyframes alternateLogos {
  14% {
    background-image: url("2dc61e7239e55b5f07f5e4caa4c218c4.png");
  }
  28% {
    background-image: url("fa8b9450a9cbff103ba90ff2f27b549e.png");
  }
  42% {
    background-image: url("6b264daedda083d237171b5d760600d0.png");
  }
  56% {
    background-image: url("aacafae4b38e9e5a8ae36f8cbe359684.png");
  }
  70% {
    background-image: url("beba198cee08798f03a09a42e92e922e.png");
  }
  84% {
    background-image: url("d8382011f33351d866213cdca342501d.png");
  }
  98% {
    background-image: url("e18aeb55df190f3f8f00af6150a216bb.png");
  }
}
@-o-keyframes alternateLogos {
  14% {
    background-image: url("2dc61e7239e55b5f07f5e4caa4c218c4.png");
  }
  28% {
    background-image: url("fa8b9450a9cbff103ba90ff2f27b549e.png");
  }
  42% {
    background-image: url("6b264daedda083d237171b5d760600d0.png");
  }
  56% {
    background-image: url("aacafae4b38e9e5a8ae36f8cbe359684.png");
  }
  70% {
    background-image: url("beba198cee08798f03a09a42e92e922e.png");
  }
  84% {
    background-image: url("d8382011f33351d866213cdca342501d.png");
  }
  98% {
    background-image: url("e18aeb55df190f3f8f00af6150a216bb.png");
  }
}
@keyframes alternateLogos {
  14% {
    background-image: url("2dc61e7239e55b5f07f5e4caa4c218c4.png");
  }
  28% {
    background-image: url("fa8b9450a9cbff103ba90ff2f27b549e.png");
  }
  42% {
    background-image: url("6b264daedda083d237171b5d760600d0.png");
  }
  56% {
    background-image: url("aacafae4b38e9e5a8ae36f8cbe359684.png");
  }
  70% {
    background-image: url("beba198cee08798f03a09a42e92e922e.png");
  }
  84% {
    background-image: url("d8382011f33351d866213cdca342501d.png");
  }
  98% {
    background-image: url("e18aeb55df190f3f8f00af6150a216bb.png");
  }
}
.navigation .collapsed span,
.navigation a,
.menu a {
  position: relative;
  text-decoration: none;
  transition: color 0.3s ease;
}
.navigation .collapsed span:before,
.navigation a:before,
.menu a:before {
  position: absolute;
  content: '';
  bottom: 0.15em;
  width: 100%;
  outline: 1px solid transparent;
}
@media (min-width: 1023px) {
  .navigation .collapsed span:before,
  .navigation a:before,
  .menu a:before {
    bottom: 0.05em;
  }
}
.navigation {
  display: block;
  width: calc((((100vw - 60px) / 2) * 2) + 20px);
  margin-bottom: 40px;
  margin-top: 40px;
}
@media (min-width: 767px) {
  .navigation {
    width: calc((((100vw - 280px) / 6) * 4) + 120px);
  }
}
@media (min-width: 1023px) {
  .navigation {
    width: calc((((100vw - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1520px) {
  .navigation {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1600px) {
  .navigation {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 767px) {
  .navigation {
    margin-bottom: 96px;
  }
}
@media (min-width: 1023px) {
  .navigation {
    margin-bottom: 136px;
  }
}
@media (min-width: 1520px) {
  .navigation {
    margin-bottom: 160px;
  }
}
@media (min-width: 1600px) {
  .navigation {
    margin-bottom: 240px;
  }
}
@media (min-width: 767px) {
  .navigation {
    margin-top: 96px;
  }
}
@media (min-width: 1023px) {
  .navigation {
    margin-top: 136px;
  }
}
@media (min-width: 1520px) {
  .navigation {
    margin-top: 160px;
  }
}
@media (min-width: 1600px) {
  .navigation {
    margin-top: 240px;
  }
}
@media (min-width: 1023px) {
  .navigation {
    margin-top: 0px;
  }
}
.navigation .preview {
  display: none;
}
.navigation ul {
  list-style: none;
  overflow: auto;
  padding: 0;
  margin: 0;
}
.navigation ul ul {
  display: block;
  opacity: 0;
  transition: opacity 0.8s ease 0.2s, max-height 0.6s ease;
  max-height: 2em;
}
.navigation .break-after {
  margin-bottom: 32px;
}
@media (min-width: 767px) {
  .navigation .break-after {
    margin-bottom: 64px;
  }
}
@media (min-width: 1023px) {
  .navigation .break-after {
    margin-bottom: 80px;
  }
}
@media (min-width: 1520px) {
  .navigation .break-after {
    margin-bottom: 88px;
  }
}
@media (min-width: 1600px) {
  .navigation .break-after {
    margin-bottom: 88px;
  }
}
.navigation .visible ul {
  opacity: 1;
  max-height: 1000px;
}
.navigation .collapsed ul {
  display: none;
}
.navigation .collapsed span {
  display: inline-block;
  cursor: pointer;
  transition: color 0.3s ease;
  color: #191919;
}
.navigation .collapsed span::after {
  white-space: nowrap;
  position: absolute;
  transform: rotate(90deg) translateX(0.15em) translateY(-0.2em);
  content: '->';
  bottom: 0;
  left: 100%;
}
.navigation li {
  font-weight: 700;
  font-size: 40px;
  line-height: 1.4;
  color: #191919;
  transition: opacity 0.3s ease;
}
@media (min-width: 767px) {
  .navigation li {
    font-size: 72px;
    line-height: 1.4;
  }
}
@media (min-width: 1023px) {
  .navigation li {
    font-size: 88px;
    line-height: 1.4;
  }
}
@media (min-width: 1520px) {
  .navigation li {
    font-size: 96px;
    line-height: 1.4;
  }
}
@media (max-width: 767px) {
  .navigation li {
    line-height: 1.6;
  }
}
.navigation li.faded {
  opacity: 0.2;
}
.navigation a {
  display: inline-block;
  color: #191919;
}
.navigation a.arrow-link:hover {
  opacity: 1;
}
@media (min-width: 1023px) and (min-width: 767px) {
  .navigation {
    margin-top: 0px;
  }
}
@media (min-width: 1023px) and (min-width: 1023px) {
  .navigation {
    margin-top: 0px;
  }
}
@media (min-width: 1023px) and (min-width: 1520px) {
  .navigation {
    margin-top: 0px;
  }
}
@media (min-width: 1023px) and (min-width: 1600px) {
  .navigation {
    margin-top: 0px;
  }
}
.navigation-preview {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: -1;
  display: none;
  pointer-events: none;
}
.navigation-preview img {
  object-position: 50% 50%;
  object-fit: cover;
  width: 100%;
}
.navigation-preview video {
  object-position: 50% 50%;
  object-fit: cover;
  width: 100%;
}
.navigation-preview .preview {
  position: absolute;
  transition: opacity 0.5s ease;
}
.navigation-preview .about {
  top: 20%;
  left: 30%;
  right: 5%;
}
.navigation-preview .careers {
  height: 50%;
  width: 50%;
  right: 5%;
  bottom: 25%;
}
.navigation-preview .contact {
  top: 30%;
  left: 30%;
  right: 5%;
}
.navigation-preview .sketch {
  height: 50%;
  width: 50%;
  left: 35%;
  bottom: 5%;
}
.navigation-preview .wetransfer {
  top: 25%;
  left: 30%;
  right: 5%;
}
.navigation-preview .coffeebybenjamin {
  height: 50%;
  width: 50%;
  right: 5%;
  bottom: 10%;
}
.navigation-preview .beam {
  top: 20%;
  left: 20%;
  right: 5%;
}
.navigation-preview .sonos {
  height: 60%;
  width: 60%;
  right: 5%;
  bottom: 20%;
}
.navigation-preview .pause {
  top: 20%;
  left: 25%;
  right: 0;
}
.navigation-preview .redbull {
  top: 20%;
  left: 20%;
  right: 5%;
}
.navigation-preview .sketchmirror {
  top: 10%;
  left: 5%;
  right: 25%;
}
.navigation-preview .finerfields {
  top: 5%;
  left: 40%;
  right: 5%;
}
.showcase {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 48px;
  width: 100%;
  overflow: hidden;
}
@media (min-width: 767px) {
  .showcase {
    margin-bottom: 96px;
  }
}
@media (min-width: 1023px) {
  .showcase {
    margin-bottom: 136px;
  }
}
@media (min-width: 1520px) {
  .showcase {
    margin-bottom: 160px;
  }
}
@media (min-width: 1600px) {
  .showcase {
    margin-bottom: 160px;
  }
}
.showcase::after {
  background: #e7e8eb;
  position: absolute;
  content: '';
  display: block;
  z-index: -2;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.showcase .title::after {
  white-space: pre;
  display: block;
  content: '\A';
}
.showcase-light .title,
.showcase-darklight .title {
  border-bottom-color: #fff;
  color: #fff;
  text-shadow: 0px 2px 24px rgba(0,0,0,0.16);
}
.showcase > img {
  object-position: 50% 50%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
  display: block;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.showcase > video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  max-height: 200%;
  min-width: 100%;
  max-width: 200%;
  transform: translate3d(-50%, -50%, 0);
  z-index: -1;
  display: block;
}
.arrow-link,
.error_404 a,
.article p a,
.blocks .block-link,
.blocks .read-more,
.links a,
.subtitle a,
.title a {
  color: #191919;
  text-decoration: none;
  padding-bottom: 0.1em;
  border-bottom: 1px solid #191919;
  transition: opacity 0.3s ease;
}
@media (min-width: 1023px) {
  .arrow-link,
  .error_404 a,
  .article p a,
  .blocks .block-link,
  .blocks .read-more,
  .links a,
  .subtitle a,
  .title a {
    border-bottom-width: 2px;
  }
}
.arrow-link::after,
.error_404 a::after,
.article p a::after,
.blocks .block-link::after,
.blocks .read-more::after,
.links a::after,
.subtitle a::after,
.title a::after {
  transition: margin-left 0.3s ease;
}
.arrow-link:hover,
.error_404 a:hover,
.article p a:hover,
.blocks .block-link:hover,
.blocks .read-more:hover,
.links a:hover,
.subtitle a:hover,
.title a:hover,
.arrow-link:focus,
.error_404 a:focus,
.article p a:focus,
.blocks .block-link:focus,
.blocks .read-more:focus,
.links a:focus,
.subtitle a:focus,
.title a:focus {
  opacity: 0.65;
}
.arrow-link:hover::after,
.error_404 a:hover::after,
.article p a:hover::after,
.blocks .block-link:hover::after,
.blocks .read-more:hover::after,
.links a:hover::after,
.subtitle a:hover::after,
.title a:hover::after,
.arrow-link:focus::after,
.error_404 a:focus::after,
.article p a:focus::after,
.blocks .block-link:focus::after,
.blocks .read-more:focus::after,
.links a:focus::after,
.subtitle a:focus::after,
.title a:focus::after {
  margin-left: 3px;
}
.arrow-link {
  color: inherit;
  border-bottom: inherit;
  display: inline;
  margin-right: auto;
  margin-bottom: 24px;
}
@media (min-width: 767px) {
  .arrow-link {
    margin-bottom: 40px;
  }
}
@media (min-width: 1023px) {
  .arrow-link {
    margin-bottom: 32px;
  }
}
@media (min-width: 1520px) {
  .arrow-link {
    margin-bottom: 40px;
  }
}
@media (min-width: 1600px) {
  .arrow-link {
    margin-bottom: 40px;
  }
}
.arrow-link::after {
  content: ' ->';
}
.footer-sticky {
  position: sticky;
  bottom: 0;
}
.footer {
  display: block;
  width: 100%;
  background-color: #000;
  margin: auto;
  color: #fff;
  box-sizing: border-box;
}
.footer.footer-light {
  background-color: #fff;
  color: #191919;
}
.footer.footer-light path {
  fill: #191919;
}
.footer p {
  flex: auto;
  opacity: 0.5;
  font-size: 12px;
}
.footer a {
  color: #fff;
  text-decoration: none;
}
.footer h1 {
  font-size: 24px;
  line-height: 40px;
  margin: 0;
}
@media (min-width: 767px) {
  .footer h1 {
    font-size: 40px;
    line-height: 72px;
  }
}
.footer-action {
  width: calc((((100vw - 60px) / 2) * 2) + 20px);
  margin: auto;
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  line-height: 1;
}
@media (min-width: 767px) {
  .footer-action {
    width: calc((((100vw - 280px) / 6) * 4) + 120px);
  }
}
@media (min-width: 1023px) {
  .footer-action {
    width: calc((((100vw - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1520px) {
  .footer-action {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1600px) {
  .footer-action {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
.footer-link {
  border-bottom: 1px solid #fff;
  margin: auto;
}
.footer-bottom {
  display: flex;
  width: calc((((100vw - 60px) / 2) * 2) + 20px);
  flex-direction: column;
  margin: auto;
  padding-top: 0;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 767px) {
  .footer-bottom {
    width: calc((((100vw - 280px) / 6) * 6) + 200px);
  }
}
@media (min-width: 1023px) {
  .footer-bottom {
    width: calc((((100vw - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 1520px) {
  .footer-bottom {
    width: calc((((1520px - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 1600px) {
  .footer-bottom {
    width: calc((((1520px - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 767px) {
  .footer-bottom {
    flex-direction: row-reverse;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 1520px) {
  .footer-bottom {
    width: calc(100vw - 200px);
    max-width: 2000px;
  }
}
.footer-bottom ul,
.footer-bottom p {
  margin: 0;
}
.footer-bottom p {
  margin-top: 24px;
}
@media (min-width: 767px) {
  .footer-bottom p {
    margin-top: 0px;
  }
}
.footer-nav {
  display: flex;
  list-style: none;
  padding: 0;
}
.social-item {
  margin-right: 20px;
  opacity: 0.5;
  width: 16px;
  height: 16px;
}
@media (min-width: 1023px) {
  .social-item {
    margin-right: 0;
    margin-left: 20px;
  }
}
.social-item:hover {
  opacity: 1;
  transition: opacity ease 0.3s;
}
.menu {
  display: none;
  list-style: none;
  padding: 0;
  font-size: 40px;
  font-weight: 600;
  line-height: 64px;
  margin-top: 22px;
}
.menu a {
  color: #191919;
  text-decoration: none;
}
.menu a.active {
  border-bottom: 2px solid;
}
@media (min-width: 1023px) {
  .menu {
    display: flex;
    margin-left: auto;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
  }
  .menu a {
    color: rgba(25,25,25,0.3);
    padding-bottom: 4px;
  }
  .menu a.active,
  .menu a:hover {
    color: #191919;
  }
  .menu li+li {
    margin-left: 24px;
  }
}
header ul.footer-nav {
  display: none;
  position: fixed;
  bottom: 60px;
  left: 20px;
}
header ul.footer-nav a {
  color: #8c8c8c;
}
header .footer-bottom {
  position: absolute;
  z-index: -1;
}
.menu-control {
  display: inline-block;
  position: absolute;
  z-index: 2;
  top: 54px;
  right: 20px;
}
@media (min-width: 1023px) {
  .menu-control {
    display: none;
  }
}
input.menu-control {
  display: none;
}
label.menu-control {
  width: 16px;
  height: 11px;
  cursor: pointer;
  background: url("be39d49cfae30aaf2b0ba61de2108190.svg");
}
.menu-control:checked + label.menu-control {
  position: fixed;
  width: 14px;
  height: 14px;
  background: url("fce83343429c215de9f6507eff21d2a3.svg");
}
@media (max-width: 1023px) {
  .menu-control:checked ~ header {
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 1;
  }
  .menu-control:checked ~ header .menu {
    display: block;
    padding-left: 20px;
  }
  .menu-control:checked ~ header ul.footer-nav {
    display: flex;
  }
}
.menu-control:checked ~ header .logo {
  position: absolute;
  top: 15px;
  left: 20px;
}
.error_404 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 24px;
  flex-direction: column;
}
.error_404::before {
  display: inline-block;
  margin: 0 auto;
  content: '';
  width: 140px;
  height: 210px;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  animation: alternateBigLogos 4s forwards infinite;
}
.error_404 h2 {
  margin: 0;
}
.error_404 a {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.6;
  margin: 0;
}
@media (min-width: 767px) {
  .error_404 a {
    font-size: 20px;
    line-height: 1.6;
  }
}
@media (min-width: 1023px) {
  .error_404 a {
    font-size: 24px;
    line-height: 1.6;
  }
}
@media (min-width: 1520px) {
  .error_404 a {
    font-size: 32px;
    line-height: 1.6;
  }
}
.error_404 a::after {
  content: ' ->';
}
@-moz-keyframes alternateBigLogos {
  14% {
    background-image: url("2dc61e7239e55b5f07f5e4caa4c218c4.png");
  }
  28% {
    background-image: url("fa8b9450a9cbff103ba90ff2f27b549e.png");
  }
  42% {
    background-image: url("6b264daedda083d237171b5d760600d0.png");
  }
  56% {
    background-image: url("aacafae4b38e9e5a8ae36f8cbe359684.png");
  }
  70% {
    background-image: url("beba198cee08798f03a09a42e92e922e.png");
  }
  84% {
    background-image: url("d8382011f33351d866213cdca342501d.png");
  }
  98% {
    background-image: url("e18aeb55df190f3f8f00af6150a216bb.png");
  }
}
@-webkit-keyframes alternateBigLogos {
  14% {
    background-image: url("2dc61e7239e55b5f07f5e4caa4c218c4.png");
  }
  28% {
    background-image: url("fa8b9450a9cbff103ba90ff2f27b549e.png");
  }
  42% {
    background-image: url("6b264daedda083d237171b5d760600d0.png");
  }
  56% {
    background-image: url("aacafae4b38e9e5a8ae36f8cbe359684.png");
  }
  70% {
    background-image: url("beba198cee08798f03a09a42e92e922e.png");
  }
  84% {
    background-image: url("d8382011f33351d866213cdca342501d.png");
  }
  98% {
    background-image: url("e18aeb55df190f3f8f00af6150a216bb.png");
  }
}
@-o-keyframes alternateBigLogos {
  14% {
    background-image: url("2dc61e7239e55b5f07f5e4caa4c218c4.png");
  }
  28% {
    background-image: url("fa8b9450a9cbff103ba90ff2f27b549e.png");
  }
  42% {
    background-image: url("6b264daedda083d237171b5d760600d0.png");
  }
  56% {
    background-image: url("aacafae4b38e9e5a8ae36f8cbe359684.png");
  }
  70% {
    background-image: url("beba198cee08798f03a09a42e92e922e.png");
  }
  84% {
    background-image: url("d8382011f33351d866213cdca342501d.png");
  }
  98% {
    background-image: url("e18aeb55df190f3f8f00af6150a216bb.png");
  }
}
@keyframes alternateBigLogos {
  14% {
    background-image: url("2dc61e7239e55b5f07f5e4caa4c218c4.png");
  }
  28% {
    background-image: url("fa8b9450a9cbff103ba90ff2f27b549e.png");
  }
  42% {
    background-image: url("6b264daedda083d237171b5d760600d0.png");
  }
  56% {
    background-image: url("aacafae4b38e9e5a8ae36f8cbe359684.png");
  }
  70% {
    background-image: url("beba198cee08798f03a09a42e92e922e.png");
  }
  84% {
    background-image: url("d8382011f33351d866213cdca342501d.png");
  }
  98% {
    background-image: url("e18aeb55df190f3f8f00af6150a216bb.png");
  }
}
.arrow-long-reversed::after {
  content: ' <—';
}
.arrow-long::after {
  content: ' —>';
}
.arrow-short-reversed::after {
  content: ' <-';
}
.arrow-short::after {
  content: ' ->';
}
.article {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 48px;
}
@media (min-width: 767px) {
  .article {
    margin-bottom: 104px;
  }
}
@media (min-width: 1023px) {
  .article {
    margin-bottom: 136px;
  }
}
@media (min-width: 1520px) {
  .article {
    margin-bottom: 160px;
  }
}
@media (min-width: 1600px) {
  .article {
    margin-bottom: 160px;
  }
}
.article :last-child {
  margin-bottom: 0 !important;
}
.article .lead {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.6;
  margin: 0;
  color: #191919;
}
@media (min-width: 767px) {
  .article .lead {
    font-size: 20px;
    line-height: 1.6;
  }
}
@media (min-width: 1023px) {
  .article .lead {
    font-size: 24px;
    line-height: 1.6;
  }
}
@media (min-width: 1520px) {
  .article .lead {
    font-size: 32px;
    line-height: 1.6;
  }
}
.article .subtitle,
.article .lead {
  margin-bottom: 24px;
}
@media (min-width: 767px) {
  .article .subtitle,
  .article .lead {
    margin-bottom: 24px;
  }
}
@media (min-width: 1023px) {
  .article .subtitle,
  .article .lead {
    margin-bottom: 32px;
  }
}
@media (min-width: 1520px) {
  .article .subtitle,
  .article .lead {
    margin-bottom: 40px;
  }
}
@media (min-width: 1600px) {
  .article .subtitle,
  .article .lead {
    margin-bottom: 40px;
  }
}
.article p {
  font-weight: 400;
  font-size: 16px;
  line-height: 2;
  margin: 0;
  color: #191919;
  width: calc((((100vw - 60px) / 2) * 2) + 20px);
  margin-bottom: 24px;
}
@media (min-width: 767px) {
  .article p {
    font-size: 16px;
    line-height: 2;
  }
}
@media (min-width: 1023px) {
  .article p {
    font-size: 20px;
    line-height: 2;
  }
}
@media (min-width: 1520px) {
  .article p {
    font-size: 24px;
    line-height: 2;
  }
}
@media (min-width: 767px) {
  .article p {
    width: calc((((100vw - 280px) / 6) * 4) + 120px);
  }
}
@media (min-width: 1023px) {
  .article p {
    width: calc((((100vw - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1520px) {
  .article p {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1600px) {
  .article p {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 767px) {
  .article p {
    margin-bottom: 24px;
  }
}
@media (min-width: 1023px) {
  .article p {
    margin-bottom: 32px;
  }
}
@media (min-width: 1520px) {
  .article p {
    margin-bottom: 40px;
  }
}
@media (min-width: 1600px) {
  .article p {
    margin-bottom: 40px;
  }
}
.backdrop {
  width: calc((((100vw - 60px) / 2) * 2) + 20px);
  margin-bottom: 48px;
  background: #eee;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 767px) {
  .backdrop {
    width: calc((((100vw - 280px) / 6) * 6) + 200px);
  }
}
@media (min-width: 1023px) {
  .backdrop {
    width: calc((((100vw - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 1520px) {
  .backdrop {
    width: calc((((1520px - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 1600px) {
  .backdrop {
    width: calc((((1520px - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 767px) {
  .backdrop {
    margin-bottom: 104px;
  }
}
@media (min-width: 1023px) {
  .backdrop {
    margin-bottom: 136px;
  }
}
@media (min-width: 1520px) {
  .backdrop {
    margin-bottom: 160px;
  }
}
@media (min-width: 1600px) {
  .backdrop {
    margin-bottom: 160px;
  }
}
@media (min-width: 1520px) {
  .backdrop {
    width: calc(100vw - 200px);
    max-width: 2000px;
  }
}
.backdrop.smaller-margin {
  margin-bottom: 20px;
}
@media (min-width: 767px) {
  .backdrop.smaller-margin {
    margin-bottom: 40px;
  }
}
@media (min-width: 1023px) {
  .backdrop.smaller-margin {
    margin-bottom: 40px;
  }
}
@media (min-width: 1520px) {
  .backdrop.smaller-margin {
    margin-bottom: 40px;
  }
}
@media (min-width: 1600px) {
  .backdrop.smaller-margin {
    margin-bottom: 40px;
  }
}
.backdrop img {
  max-width: calc(100% - 20px);
}
.backdrop .device {
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding: 0;
  margin: 0;
  width: auto;
}
.backdrop .device:not(.cut-off) {
  margin-top: 8px;
  margin-bottom: 8px;
}
@media (min-width: 767px) {
  .backdrop .device:not(.cut-off) {
    margin-top: 32px;
  }
}
@media (min-width: 1023px) {
  .backdrop .device:not(.cut-off) {
    margin-top: 32px;
  }
}
@media (min-width: 1520px) {
  .backdrop .device:not(.cut-off) {
    margin-top: 32px;
  }
}
@media (min-width: 1600px) {
  .backdrop .device:not(.cut-off) {
    margin-top: 32px;
  }
}
@media (min-width: 767px) {
  .backdrop .device:not(.cut-off) {
    margin-bottom: 32px;
  }
}
@media (min-width: 1023px) {
  .backdrop .device:not(.cut-off) {
    margin-bottom: 32px;
  }
}
@media (min-width: 1520px) {
  .backdrop .device:not(.cut-off) {
    margin-bottom: 32px;
  }
}
@media (min-width: 1600px) {
  .backdrop .device:not(.cut-off) {
    margin-bottom: 32px;
  }
}
@media (max-width: 767px) {
  .backdrop .device video {
    display: block;
    margin: 16px;
    max-width: calc(100% - 32px);
  }
}
@media (min-width: 767px) {
  .backdrop .device::before {
    pointer-events: none;
    position: absolute;
    content: '';
    z-index: 5;
  }
  .backdrop .device::after {
    position: absolute;
    content: '';
    z-index: 3;
  }
  .backdrop .device video {
    position: absolute;
    z-index: 4;
    margin: 0;
  }
  .backdrop .device.iphone-se {
    height: 797px;
    width: 380px;
  }
  .backdrop .device.iphone-se.cut-off {
    margin-top: -90px;
    margin-bottom: -70px;
  }
  .backdrop .device.iphone-se video {
    top: 14.868%;
    left: 8.421%;
    width: 84.21100000000001%;
  }
  .backdrop .device.iphone-se::after {
    background: #000;
    border-radius: 10%;
    bottom: 12px;
    right: 12px;
    left: 12px;
    top: 12px;
  }
  .backdrop .device.iphone-se::before {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("e92345473a85626d88c2a67efe1adf17.png");
  }
  .backdrop .device.iphone-se.silver::before {
    background-image: url("93e91559f01bd5b6ebbb0afa7edbb4c7.png");
  }
  .backdrop .device.iphone-8 {
    height: 888px;
    width: 435.5px;
  }
  .backdrop .device.iphone-8.cut-off {
    margin-top: -85px;
    margin-bottom: -70px;
  }
  .backdrop .device.iphone-8 video {
    top: 12.324%;
    left: 7.003%;
    width: 86.108%;
  }
  .backdrop .device.iphone-8::after {
    background: #000;
    border-radius: 10%;
    bottom: 8px;
    right: 8px;
    left: 8px;
    top: 8px;
  }
  .backdrop .device.iphone-8::before {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("007ec7b6b8d26490095bb2fc7dccaebd.png");
  }
  .backdrop .device.iphone-8.silver::before {
    background-image: url("ee74ad86413a9a99d623b2a8a1e212f2.png");
  }
  .backdrop .device.iphone-8.gold::before {
    background-image: url("dd05c71c02e09d0154c5486db702f92c.png");
  }
  .backdrop .device.iphone-x {
    height: 1295px;
    width: 647.5px;
  }
  .backdrop .device.iphone-x.cut-off {
    margin-top: -60px;
    margin-bottom: -60px;
  }
  .backdrop .device.iphone-x video {
    top: 2.973%;
    left: 6.564%;
    width: 86.87200000000001%;
  }
  .backdrop .device.iphone-x::after {
    background: #000;
    border-radius: 10%;
    bottom: 8px;
    right: 8px;
    left: 8px;
    top: 8px;
  }
  .backdrop .device.iphone-x::before {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("4b7aa6295cf04d623ac781ece9755e07.png");
  }
  .backdrop .device.iphone-x.silver::before {
    background-image: url("8a1dfb3b05e27d14bfde73e8835fc0d7.png");
  }
}
.blocks {
  margin-bottom: 24px;
  width: calc((((100vw - 60px) / 2) * 2) + 20px);
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 767px) {
  .blocks {
    margin-bottom: 40px;
  }
}
@media (min-width: 1023px) {
  .blocks {
    margin-bottom: 64px;
  }
}
@media (min-width: 1520px) {
  .blocks {
    margin-bottom: 80px;
  }
}
@media (min-width: 1600px) {
  .blocks {
    margin-bottom: 80px;
  }
}
@media (min-width: 767px) {
  .blocks {
    width: calc((((100vw - 280px) / 6) * 4) + 120px);
  }
}
@media (min-width: 1023px) {
  .blocks {
    width: calc((((100vw - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1520px) {
  .blocks {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1600px) {
  .blocks {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
.blocks .block-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.6;
  padding: 0;
  margin: 0 0 4px;
  color: #191919;
}
@media (min-width: 767px) {
  .blocks .block-title {
    font-size: 20px;
    line-height: 1.6;
  }
}
@media (min-width: 1023px) {
  .blocks .block-title {
    font-size: 24px;
    line-height: 1.6;
  }
}
@media (min-width: 1520px) {
  .blocks .block-title {
    font-size: 32px;
    line-height: 1.6;
  }
}
@media (min-width: 1023px) {
  .blocks .block-title {
    margin-bottom: 8px;
  }
}
.blocks .block-link,
.blocks .read-more {
  display: inline;
  margin-right: auto;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 24px;
}
@media (min-width: 767px) {
  .blocks .block-link,
  .blocks .read-more {
    font-size: 16px;
    line-height: 1.6;
  }
}
@media (min-width: 1023px) {
  .blocks .block-link,
  .blocks .read-more {
    font-size: 20px;
    line-height: 1.6;
  }
}
@media (min-width: 1520px) {
  .blocks .block-link,
  .blocks .read-more {
    font-size: 24px;
    line-height: 1.6;
  }
}
@media (min-width: 767px) {
  .blocks .block-link,
  .blocks .read-more {
    margin-bottom: 40px;
  }
}
@media (min-width: 1023px) {
  .blocks .block-link,
  .blocks .read-more {
    margin-bottom: 32px;
  }
}
@media (min-width: 1520px) {
  .blocks .block-link,
  .blocks .read-more {
    margin-bottom: 40px;
  }
}
@media (min-width: 1600px) {
  .blocks .block-link,
  .blocks .read-more {
    margin-bottom: 40px;
  }
}
.blocks .read-more::after {
  content: ' ->';
}
.blocks p {
  font-size: 16px;
  line-height: 2;
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
  color: #191919;
}
@media (min-width: 767px) {
  .blocks p {
    font-size: 16px;
    line-height: 2;
  }
}
@media (min-width: 1023px) {
  .blocks p {
    font-size: 20px;
    line-height: 2;
  }
}
@media (min-width: 1520px) {
  .blocks p {
    font-size: 24px;
    line-height: 2;
  }
}
@media (min-width: 767px) {
  .blocks p {
    margin-bottom: 16px;
  }
}
@media (min-width: 1023px) {
  .blocks p {
    margin-bottom: 32px;
  }
}
@media (min-width: 1520px) {
  .blocks p {
    margin-bottom: 32px;
  }
}
@media (min-width: 1600px) {
  .blocks p {
    margin-bottom: 32px;
  }
}
.blocks .block {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  margin-bottom: 32px;
}
@media (min-width: 767px) {
  .blocks .block {
    margin-bottom: 48px;
  }
}
@media (min-width: 1023px) {
  .blocks .block {
    margin-bottom: 40px;
  }
}
@media (min-width: 1520px) {
  .blocks .block {
    margin-bottom: 48px;
  }
}
@media (min-width: 1600px) {
  .blocks .block {
    margin-bottom: 48px;
  }
}
@media (min-width: 767px) {
  .blocks .block {
    width: calc(50% - 20px);
  }
  .blocks .block:nth-of-type(odd) {
    margin-right: 40px;
  }
}
@media (min-width: 1023px) {
  .blocks .block {
    width: calc(50% - 20px);
  }
  .blocks .block:nth-of-type(odd) {
    margin-right: 40px;
  }
}
@media (min-width: 1520px) {
  .blocks .block {
    width: calc(50% - 20px);
  }
  .blocks .block:nth-of-type(odd) {
    margin-right: 40px;
  }
}
.diptych {
  display: flex;
  flex-direction: column;
  width: calc((((100vw - 60px) / 2) * 2) + 20px);
  justify-content: space-between;
  margin-bottom: 48px;
}
@media (min-width: 767px) {
  .diptych {
    flex-direction: row;
  }
}
@media (min-width: 767px) {
  .diptych {
    width: calc((((100vw - 280px) / 6) * 6) + 200px);
  }
}
@media (min-width: 1023px) {
  .diptych {
    width: calc((((100vw - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 1520px) {
  .diptych {
    width: calc((((1520px - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 1600px) {
  .diptych {
    width: calc((((1520px - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 767px) {
  .diptych {
    margin-bottom: 104px;
  }
}
@media (min-width: 1023px) {
  .diptych {
    margin-bottom: 136px;
  }
}
@media (min-width: 1520px) {
  .diptych {
    margin-bottom: 160px;
  }
}
@media (min-width: 1600px) {
  .diptych {
    margin-bottom: 160px;
  }
}
.diptych.smaller-margin {
  margin-bottom: 20px;
}
@media (min-width: 767px) {
  .diptych.smaller-margin {
    margin-bottom: 40px;
  }
}
@media (min-width: 1023px) {
  .diptych.smaller-margin {
    margin-bottom: 40px;
  }
}
@media (min-width: 1520px) {
  .diptych.smaller-margin {
    margin-bottom: 40px;
  }
}
@media (min-width: 1600px) {
  .diptych.smaller-margin {
    margin-bottom: 40px;
  }
}
@media (min-width: 1520px) {
  .diptych.smaller-margin {
    width: calc(100vw - 200px);
    max-width: 2000px;
  }
}
.diptych > a,
.diptych > img,
.diptych > video {
  background: #e7e8eb;
  max-width: 100%;
  align-self: center;
  flex-shrink: 1;
}
@media (max-width: 767px) {
  .diptych > a:not(:last-child),
  .diptych > img:not(:last-child),
  .diptych > video:not(:last-child) {
    margin-bottom: 20px;
  }
}
@media (min-width: 767px) {
  .diptych > a,
  .diptych > img,
  .diptych > video {
    max-width: calc(50% - 20px);
  }
}
@media (min-width: 1023px) {
  .diptych > a,
  .diptych > img,
  .diptych > video {
    max-width: calc(50% - 20px);
  }
}
@media (min-width: 1520px) {
  .diptych > a,
  .diptych > img,
  .diptych > video {
    max-width: calc(50% - 20px);
  }
}
@media (max-width: 767px) and (min-width: 767px) {
  .diptych > a:not(:last-child),
  .diptych > img:not(:last-child),
  .diptych > video:not(:last-child) {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) and (min-width: 1023px) {
  .diptych > a:not(:last-child),
  .diptych > img:not(:last-child),
  .diptych > video:not(:last-child) {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) and (min-width: 1520px) {
  .diptych > a:not(:last-child),
  .diptych > img:not(:last-child),
  .diptych > video:not(:last-child) {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) and (min-width: 1600px) {
  .diptych > a:not(:last-child),
  .diptych > img:not(:last-child),
  .diptych > video:not(:last-child) {
    margin-bottom: 40px;
  }
}
.diptych a {
  overflow: hidden;
}
.diptych a img {
  width: 100%;
  transition: transform 0.3s ease;
}
.diptych a:hover img {
  transform: scale(1.1);
}
.figure {
  position: relative;
  width: calc((((100vw - 60px) / 2) * 2) + 20px);
  margin-top: 0;
  margin-bottom: 48px;
  background: #e7e8eb;
}
@media (min-width: 767px) {
  .figure {
    width: calc((((100vw - 280px) / 6) * 6) + 200px);
  }
}
@media (min-width: 1023px) {
  .figure {
    width: calc((((100vw - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 1520px) {
  .figure {
    width: calc((((1520px - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 1600px) {
  .figure {
    width: calc((((1520px - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 767px) {
  .figure {
    margin-bottom: 104px;
  }
}
@media (min-width: 1023px) {
  .figure {
    margin-bottom: 136px;
  }
}
@media (min-width: 1520px) {
  .figure {
    margin-bottom: 160px;
  }
}
@media (min-width: 1600px) {
  .figure {
    margin-bottom: 160px;
  }
}
@media (min-width: 1520px) {
  .figure {
    width: calc(100vw - 200px);
    max-width: 2000px;
  }
}
.figure.smaller-margin {
  margin-bottom: 20px;
}
@media (min-width: 767px) {
  .figure.smaller-margin {
    margin-bottom: 40px;
  }
}
@media (min-width: 1023px) {
  .figure.smaller-margin {
    margin-bottom: 40px;
  }
}
@media (min-width: 1520px) {
  .figure.smaller-margin {
    margin-bottom: 40px;
  }
}
@media (min-width: 1600px) {
  .figure.smaller-margin {
    margin-bottom: 40px;
  }
}
.figure video,
.figure img {
  width: 100%;
  display: block;
}
.figure .mute-button {
  border: 0;
  outline: 0;
  background: url("d6201c6ab0ab08d66075f3002e586f80.svg") 0 0 no-repeat;
  background-size: contain;
  position: absolute;
  height: 31px;
  width: 31px;
  bottom: 8px;
  right: 8px;
  z-index: 10;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
@media (min-width: 767px) {
  .figure .mute-button {
    bottom: 24px;
    right: 24px;
  }
}
.figure .mute-button:hover,
.figure .mute-button:focus {
  opacity: 1;
}
.figure.muted .mute-button {
  background-image: url("6139e1882bb89e0bb7bf28237082d7d4.svg");
}
.links {
  margin: 0;
  padding: 0;
  list-style: none;
  width: calc((((100vw - 60px) / 2) * 2) + 20px);
  margin-bottom: 48px;
}
@media (min-width: 767px) {
  .links {
    width: calc((((100vw - 280px) / 6) * 4) + 120px);
  }
}
@media (min-width: 1023px) {
  .links {
    width: calc((((100vw - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1520px) {
  .links {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1600px) {
  .links {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 767px) {
  .links {
    margin-bottom: 104px;
  }
}
@media (min-width: 1023px) {
  .links {
    margin-bottom: 136px;
  }
}
@media (min-width: 1520px) {
  .links {
    margin-bottom: 160px;
  }
}
@media (min-width: 1600px) {
  .links {
    margin-bottom: 160px;
  }
}
.links a {
  display: inline-block;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 8px;
}
@media (min-width: 767px) {
  .links a {
    font-size: 16px;
    line-height: 1.6;
  }
}
@media (min-width: 1023px) {
  .links a {
    font-size: 20px;
    line-height: 1.6;
  }
}
@media (min-width: 1520px) {
  .links a {
    font-size: 24px;
    line-height: 1.6;
  }
}
@media (min-width: 767px) {
  .links a {
    margin-bottom: 8px;
  }
}
@media (min-width: 1023px) {
  .links a {
    margin-bottom: 8px;
  }
}
@media (min-width: 1520px) {
  .links a {
    margin-bottom: 8px;
  }
}
@media (min-width: 1600px) {
  .links a {
    margin-bottom: 8px;
  }
}
.links a::after {
  content: ' ->';
}
.partners {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 64px;
}
@media (min-width: 767px) {
  .partners {
    margin-bottom: 104px;
  }
}
@media (min-width: 1023px) {
  .partners {
    margin-bottom: 136px;
  }
}
@media (min-width: 1520px) {
  .partners {
    margin-bottom: 160px;
  }
}
@media (min-width: 1600px) {
  .partners {
    margin-bottom: 160px;
  }
}
.partners ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc((((100vw - 60px) / 2) * 2) + 20px);
}
@media (min-width: 767px) {
  .partners ul {
    flex-wrap: nowrap;
  }
}
@media (min-width: 767px) {
  .partners ul {
    width: calc((((100vw - 280px) / 6) * 4) + 120px);
  }
}
@media (min-width: 1023px) {
  .partners ul {
    width: calc((((100vw - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1520px) {
  .partners ul {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1600px) {
  .partners ul {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
.partners ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 33.333%;
  margin-bottom: 32px;
}
@media (min-width: 767px) {
  .partners ul li {
    margin-bottom: 48px;
  }
}
@media (min-width: 1023px) {
  .partners ul li {
    margin-bottom: 64px;
  }
}
@media (min-width: 1520px) {
  .partners ul li {
    margin-bottom: 64px;
  }
}
@media (min-width: 1600px) {
  .partners ul li {
    margin-bottom: 64px;
  }
}
.partners ul .sonos img {
  height: 14px;
}
@media (min-width: 1023px) {
  .partners ul .sonos img {
    height: 22px;
  }
}
@media (min-width: 1520px) {
  .partners ul .sonos img {
    height: 28px;
  }
}
.partners ul .sketch img {
  height: 22px;
}
@media (min-width: 1023px) {
  .partners ul .sketch img {
    height: 34px;
  }
}
@media (min-width: 1520px) {
  .partners ul .sketch img {
    height: 48px;
  }
}
.partners ul .wetransfer img {
  height: 17px;
}
@media (min-width: 1023px) {
  .partners ul .wetransfer img {
    height: 27px;
  }
}
@media (min-width: 1520px) {
  .partners ul .wetransfer img {
    height: 34px;
  }
}
.partners ul .acetate img {
  height: 14px;
}
@media (min-width: 1023px) {
  .partners ul .acetate img {
    height: 22px;
  }
}
@media (min-width: 1520px) {
  .partners ul .acetate img {
    height: 28px;
  }
}
.partners ul .revue img {
  height: 22px;
}
@media (min-width: 1023px) {
  .partners ul .revue img {
    height: 35px;
  }
}
@media (min-width: 1520px) {
  .partners ul .revue img {
    height: 45px;
  }
}
.partners ul .redbull img {
  height: 25px;
}
@media (min-width: 1023px) {
  .partners ul .redbull img {
    height: 40px;
  }
}
@media (min-width: 1520px) {
  .partners ul .redbull img {
    height: 51px;
  }
}
@media (min-width: 1023px) {
  .partners ul .revue,
  .partners ul .sonos,
  .partners ul .sketch,
  .partners ul .redbull,
  .partners ul .wetransfer {
    width: 15%;
  }
  .partners ul .acetate {
    width: 25%;
  }
}
.screenshots {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  width: calc((((100vw - 60px) / 2) * 2) + 20px);
  justify-content: space-between;
  margin-bottom: 48px;
}
@media (min-width: 767px) {
  .screenshots {
    width: calc((((100vw - 280px) / 6) * 4) + 120px);
  }
}
@media (min-width: 1023px) {
  .screenshots {
    width: calc((((100vw - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1520px) {
  .screenshots {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1600px) {
  .screenshots {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 767px) {
  .screenshots {
    margin-bottom: 104px;
  }
}
@media (min-width: 1023px) {
  .screenshots {
    margin-bottom: 136px;
  }
}
@media (min-width: 1520px) {
  .screenshots {
    margin-bottom: 160px;
  }
}
@media (min-width: 1600px) {
  .screenshots {
    margin-bottom: 160px;
  }
}
.screenshots.full-width {
  width: calc((((100vw - 60px) / 2) * 2) + 20px);
}
@media (min-width: 767px) {
  .screenshots.full-width {
    width: calc((((100vw - 280px) / 6) * 6) + 200px);
  }
}
@media (min-width: 1023px) {
  .screenshots.full-width {
    width: calc((((100vw - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 1520px) {
  .screenshots.full-width {
    width: calc((((1520px - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 1600px) {
  .screenshots.full-width {
    width: calc((((1520px - 520px) / 12) * 12) + 440px);
  }
}
@media (min-width: 1520px) {
  .screenshots.full-width {
    width: calc(100vw - 200px);
    max-width: 2000px;
  }
}
.screenshots.smaller-margin {
  margin-bottom: 20px;
}
@media (min-width: 767px) {
  .screenshots.smaller-margin {
    margin-bottom: 40px;
  }
}
@media (min-width: 1023px) {
  .screenshots.smaller-margin {
    margin-bottom: 40px;
  }
}
@media (min-width: 1520px) {
  .screenshots.smaller-margin {
    margin-bottom: 40px;
  }
}
@media (min-width: 1600px) {
  .screenshots.smaller-margin {
    margin-bottom: 40px;
  }
}
.screenshots .screenshot {
  align-self: center;
  flex-shrink: 1;
  margin: 0;
  margin-bottom: 16px;
}
@media (min-width: 767px) {
  .screenshots .screenshot {
    margin-bottom: 32px;
  }
}
@media (min-width: 1023px) {
  .screenshots .screenshot {
    margin-bottom: 64px;
  }
}
@media (min-width: 1520px) {
  .screenshots .screenshot {
    margin-bottom: 64px;
  }
}
@media (min-width: 1600px) {
  .screenshots .screenshot {
    margin-bottom: 64px;
  }
}
@media (min-width: 767px) {
  .screenshots .screenshot:first-child:nth-last-child(1),
  .screenshots .screenshot:first-child:nth-last-child(1) ~ .screenshot {
    margin-right: auto;
    margin-left: auto;
    max-width: calc(50% - 20px);
  }
  .screenshots .screenshot:first-child:nth-last-child(2),
  .screenshots .screenshot:first-child:nth-last-child(2) ~ .screenshot {
    max-width: calc(50% - 20px);
  }
  .screenshots .screenshot:first-child:nth-last-child(3),
  .screenshots .screenshot:first-child:nth-last-child(3) ~ .screenshot {
    max-width: calc(33.333% - 20px);
  }
  .screenshots .screenshot:first-child:nth-last-child(4),
  .screenshots .screenshot:first-child:nth-last-child(4) ~ .screenshot {
    max-width: calc(25% - 20px);
  }
}
.screenshots .screenshot img {
  max-width: 100%;
  margin-bottom: 8px;
}
@media (min-width: 767px) {
  .screenshots .screenshot img {
    margin-bottom: 8px;
  }
}
@media (min-width: 1023px) {
  .screenshots .screenshot img {
    margin-bottom: 8px;
  }
}
@media (min-width: 1520px) {
  .screenshots .screenshot img {
    margin-bottom: 8px;
  }
}
@media (min-width: 1600px) {
  .screenshots .screenshot img {
    margin-bottom: 8px;
  }
}
.screenshots .screenshot figcaption {
  display: block;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.6;
  color: #b6b7b9;
  max-width: 85%;
  margin: 0 auto;
}
@media (min-width: 767px) {
  .screenshots .screenshot figcaption {
    font-size: 12px;
    line-height: 1.6;
  }
}
@media (min-width: 1023px) {
  .screenshots .screenshot figcaption {
    font-size: 12px;
    line-height: 1.6;
  }
}
@media (min-width: 1520px) {
  .screenshots .screenshot figcaption {
    font-size: 12px;
    line-height: 1.6;
  }
}
@media (min-width: 767px) and (min-width: 1023px) {
  .screenshots .screenshot:first-child:nth-last-child(1),
  .screenshots .screenshot:first-child:nth-last-child(1) ~ .screenshot {
    max-width: calc(50% - 20px);
  }
}
@media (min-width: 767px) and (min-width: 1520px) {
  .screenshots .screenshot:first-child:nth-last-child(1),
  .screenshots .screenshot:first-child:nth-last-child(1) ~ .screenshot {
    max-width: calc(50% - 20px);
  }
}
@media (min-width: 767px) and (min-width: 1023px) {
  .screenshots .screenshot:first-child:nth-last-child(2),
  .screenshots .screenshot:first-child:nth-last-child(2) ~ .screenshot {
    max-width: calc(50% - 20px);
  }
}
@media (min-width: 767px) and (min-width: 1520px) {
  .screenshots .screenshot:first-child:nth-last-child(2),
  .screenshots .screenshot:first-child:nth-last-child(2) ~ .screenshot {
    max-width: calc(50% - 20px);
  }
}
@media (min-width: 767px) and (min-width: 1023px) {
  .screenshots .screenshot:first-child:nth-last-child(3),
  .screenshots .screenshot:first-child:nth-last-child(3) ~ .screenshot {
    max-width: calc(33.333% - 20px);
  }
}
@media (min-width: 767px) and (min-width: 1520px) {
  .screenshots .screenshot:first-child:nth-last-child(3),
  .screenshots .screenshot:first-child:nth-last-child(3) ~ .screenshot {
    max-width: calc(33.333% - 20px);
  }
}
@media (min-width: 767px) and (min-width: 1023px) {
  .screenshots .screenshot:first-child:nth-last-child(4),
  .screenshots .screenshot:first-child:nth-last-child(4) ~ .screenshot {
    max-width: calc(25% - 20px);
  }
}
@media (min-width: 767px) and (min-width: 1520px) {
  .screenshots .screenshot:first-child:nth-last-child(4),
  .screenshots .screenshot:first-child:nth-last-child(4) ~ .screenshot {
    max-width: calc(25% - 20px);
  }
}
.subtitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.6;
  margin: 0;
  color: #191919;
  width: calc((((100vw - 60px) / 2) * 2) + 20px);
  margin-bottom: 48px;
}
@media (min-width: 767px) {
  .subtitle {
    font-size: 24px;
    line-height: 1.6;
  }
}
@media (min-width: 1023px) {
  .subtitle {
    font-size: 32px;
    line-height: 1.6;
  }
}
@media (min-width: 1520px) {
  .subtitle {
    font-size: 48px;
    line-height: 1.6;
  }
}
@media (min-width: 767px) {
  .subtitle {
    width: calc((((100vw - 280px) / 6) * 4) + 120px);
  }
}
@media (min-width: 1023px) {
  .subtitle {
    width: calc((((100vw - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1520px) {
  .subtitle {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1600px) {
  .subtitle {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 767px) {
  .subtitle {
    margin-bottom: 80px;
  }
}
@media (min-width: 1023px) {
  .subtitle {
    margin-bottom: 104px;
  }
}
@media (min-width: 1520px) {
  .subtitle {
    margin-bottom: 160px;
  }
}
@media (min-width: 1600px) {
  .subtitle {
    margin-bottom: 160px;
  }
}
.team {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  user-select: none;
  -webkit-user-select: none;
}
.team li {
  position: relative;
  overflow: hidden;
  display: flex;
}
.team li img {
  border-radius: 100%;
  align-self: center;
  max-width: 100%;
}
.team li.slack::after {
  position: absolute;
  border-radius: 100%;
  background-position: 50%;
  background-size: 65%;
  background-repeat: no-repeat;
  display: block;
  content: '';
  border: 1px solid #fff;
  height: 25%;
  width: 25%;
  bottom: 0;
  right: 0;
}
.team li.slack::after {
  border: 0;
  background-color: transparent;
  background-size: 100%;
  background-image: url("/assets/svgs/team/slack.svg");
}
.team.inline {
  margin-top: 8px;
}
@media (min-width: 767px) {
  .team.inline {
    margin-top: 8px;
  }
}
@media (min-width: 1023px) {
  .team.inline {
    margin-top: 8px;
  }
}
@media (min-width: 1520px) {
  .team.inline {
    margin-top: 8px;
  }
}
@media (min-width: 1600px) {
  .team.inline {
    margin-top: 8px;
  }
}
@media (max-width: 767px) {
  .team.inline li {
    width: calc((100% - (3 * 35px)) / 4);
    margin-right: 35px;
    margin-bottom: 35px;
  }
  .team.inline li:nth-of-type(4n+4) {
    margin-right: 0;
  }
}
@media (min-width: 767px) {
  .team.inline li {
    width: calc((100% - (3 * 35px)) / 4);
    margin-right: 35px;
    margin-bottom: 35px;
  }
  .team.inline li:nth-of-type(4n+4) {
    margin-right: 0;
  }
}
.team.full-width {
  width: calc((((100vw - 60px) / 2) * 2) + 20px);
  margin-bottom: 13px;
}
@media (min-width: 767px) {
  .team.full-width {
    width: calc((((100vw - 280px) / 6) * 4) + 120px);
  }
}
@media (min-width: 1023px) {
  .team.full-width {
    width: calc((((100vw - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1520px) {
  .team.full-width {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1600px) {
  .team.full-width {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 767px) {
  .team.full-width {
    margin-bottom: 45px;
  }
}
@media (min-width: 1023px) {
  .team.full-width {
    margin-bottom: 101px;
  }
}
@media (min-width: 1520px) {
  .team.full-width {
    margin-bottom: 125px;
  }
}
@media (min-width: 1600px) {
  .team.full-width {
    margin-bottom: 85px;
  }
}
.team.full-width li {
  margin-right: 35px;
  margin-bottom: 35px;
}
@media (min-width: 151px) {
  .team.full-width li {
    width: calc((100% - (1 * 35px)) / 2);
  }
}
@media (min-width: 226px) {
  .team.full-width li {
    width: calc((100% - (2 * 35px)) / 3);
  }
}
@media (min-width: 301px) {
  .team.full-width li {
    width: calc((100% - (3 * 35px)) / 4);
  }
}
@media (min-width: 376px) {
  .team.full-width li {
    width: calc((100% - (4 * 35px)) / 5);
  }
}
@media (min-width: 451px) {
  .team.full-width li {
    width: calc((100% - (5 * 35px)) / 6);
  }
  .team.full-width li:nth-of-type(6n+6) {
    margin-right: 0;
  }
}
@media (min-width: 151px) and (max-width: 225px) {
  .team.full-width li:nth-of-type(2n+2) {
    margin-right: 0;
  }
}
@media (min-width: 226px) and (max-width: 300px) {
  .team.full-width li:nth-of-type(3n+3) {
    margin-right: 0;
  }
}
@media (min-width: 301px) and (max-width: 375px) {
  .team.full-width li:nth-of-type(4n+4) {
    margin-right: 0;
  }
}
@media (min-width: 376px) and (max-width: 450px) {
  .team.full-width li:nth-of-type(5n+5) {
    margin-right: 0;
  }
}
.title {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.6;
  margin: 0;
  color: #191919;
  width: calc((((100vw - 60px) / 2) * 2) + 20px);
  margin-bottom: 64px;
  margin-top: 64px;
}
@media (min-width: 767px) {
  .title {
    font-size: 36px;
    line-height: 1.6;
  }
}
@media (min-width: 1023px) {
  .title {
    font-size: 44px;
    line-height: 1.6;
  }
}
@media (min-width: 1520px) {
  .title {
    font-size: 52px;
    line-height: 1.6;
  }
}
@media (min-width: 767px) {
  .title {
    width: calc((((100vw - 280px) / 6) * 4) + 120px);
  }
}
@media (min-width: 1023px) {
  .title {
    width: calc((((100vw - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1520px) {
  .title {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 1600px) {
  .title {
    width: calc((((1520px - 520px) / 12) * 8) + 280px);
  }
}
@media (min-width: 767px) {
  .title {
    margin-bottom: 96px;
  }
}
@media (min-width: 1023px) {
  .title {
    margin-bottom: 136px;
  }
}
@media (min-width: 1520px) {
  .title {
    margin-bottom: 144px;
  }
}
@media (min-width: 1600px) {
  .title {
    margin-bottom: 240px;
  }
}
@media (min-width: 767px) {
  .title {
    margin-top: 96px;
  }
}
@media (min-width: 1023px) {
  .title {
    margin-top: 136px;
  }
}
@media (min-width: 1520px) {
  .title {
    margin-top: 144px;
  }
}
@media (min-width: 1600px) {
  .title {
    margin-top: 240px;
  }
}
@media (min-width: 1023px) {
  .title {
    margin-top: 0px;
  }
}
@media (min-width: 1520px) {
  .title span {
    display: block;
  }
  .title span::before {
    content: attr(data-text);
  }
}
.showcase .title {
  margin-bottom: 80px;
  margin-top: 144px;
}
@media (min-width: 767px) {
  .showcase .title {
    margin-bottom: 192px;
  }
}
@media (min-width: 1023px) {
  .showcase .title {
    margin-bottom: 272px;
  }
}
@media (min-width: 1520px) {
  .showcase .title {
    margin-bottom: 288px;
  }
}
@media (min-width: 1600px) {
  .showcase .title {
    margin-bottom: 288px;
  }
}
@media (min-width: 767px) {
  .showcase .title {
    margin-top: 192px;
  }
}
@media (min-width: 1023px) {
  .showcase .title {
    margin-top: 272px;
  }
}
@media (min-width: 1520px) {
  .showcase .title {
    margin-top: 288px;
  }
}
@media (min-width: 1600px) {
  .showcase .title {
    margin-top: 288px;
  }
}
@media (min-width: 1023px) {
  .showcase .title {
    margin-bottom: 80px;
    margin-top: 80px;
  }
}
@media (min-width: 1023px) and (min-width: 767px) {
  .showcase .title {
    margin-bottom: 192px;
  }
}
@media (min-width: 1023px) and (min-width: 1023px) {
  .showcase .title {
    margin-bottom: 272px;
  }
}
@media (min-width: 1023px) and (min-width: 1520px) {
  .showcase .title {
    margin-bottom: 288px;
  }
}
@media (min-width: 1023px) and (min-width: 1600px) {
  .showcase .title {
    margin-bottom: 288px;
  }
}
@media (min-width: 1023px) and (min-width: 767px) {
  .showcase .title {
    margin-top: 192px;
  }
}
@media (min-width: 1023px) and (min-width: 1023px) {
  .showcase .title {
    margin-top: 272px;
  }
}
@media (min-width: 1023px) and (min-width: 1520px) {
  .showcase .title {
    margin-top: 288px;
  }
}
@media (min-width: 1023px) and (min-width: 1600px) {
  .showcase .title {
    margin-top: 288px;
  }
}
@media (min-width: 1023px) and (min-width: 767px) {
  .title {
    margin-top: 0px;
  }
}
@media (min-width: 1023px) and (min-width: 1023px) {
  .title {
    margin-top: 0px;
  }
}
@media (min-width: 1023px) and (min-width: 1520px) {
  .title {
    margin-top: 0px;
  }
}
@media (min-width: 1023px) and (min-width: 1600px) {
  .title {
    margin-top: 0px;
  }
}
.contact {
  margin-bottom: 8px;
}
@media (min-width: 767px) {
  .contact {
    margin-bottom: 16px;
  }
}
@media (min-width: 1023px) {
  .contact {
    margin-bottom: 24px;
  }
}
@media (min-width: 1520px) {
  .contact {
    margin-bottom: 32px;
  }
}
@media (min-width: 1600px) {
  .contact {
    margin-bottom: 40px;
  }
}
